import {
	requests
}from '../../../../../api/agent'

export default {
	CreateReOrgAppealMission			 		: (params) => requests.post('/ReOrgAppealMission/CreateReOrgAppealMission', params), // Создание поручения
	ReadReOrgAppealMission				 		: (params) => requests.getWithParams('/ReOrgAppealMission/ReadReOrgAppealMission', params), // Получение информации о поручении
	RegisterReOrgAppealMission 			 		: (params) => requests.post('/ReOrgAppealMission/RegisterReOrgAppealMission', params), // Регистрация поручения получателем
	UpdateReOrgAppealMissionByReceiver   		: (params) => requests.post('/ReOrgAppealMission/UpdateReOrgAppealMissionByReceiver', params), // Редактирование поручения получателем
	CreateReOrgAppealMissionResponse 	 		: (params) => requests.post('/ReOrgAppealMission/CreateReOrgAppealMissionResponse', params), // Создание ответа на поручение
	GetReOrgAppealMissionsList			 		: (params) => requests.getWithParams('/ReOrgAppealMission/GetReOrgAppealMissionsList', params), // Получение списка поручений
	GetReOrgAppealMissionsPaged			 		: (params) => requests.post('/ReOrgAppealMission/GetReOrgAppealMissionsPaged', params), // Получение поручений в построничном виде
	GetReOrgAppealMissionsForReOrgAppealPaged   : (params) => requests.getWithParams('/ReOrgAppealMission/GetReOrgAppealMissionsForReOrgAppealPaged', params), // Получение поручений для страницы обращения
	GetReOrgAppealMissionFile			 		: (params) => requests.getFile('/ReOrgAppealMission/DownloadReOrgAppealMissionFile', params), // Скачивание файла поручения
	GetReOrgAppealMissionFiles			 		: (params) => requests.getFile('/ReOrgAppealMission/DownloadAllReOrgAppealMissionFiles', params), // Скачивание архива со всеми файлами поручения
	GetReOrgAppealMissionResponseFile	 		: (params) => requests.getFile('/ReOrgAppealMission/DownloadReOrgAppealMissionResponseFile', params), // Скачивание файла ответа поручения
	GetReOrgAppealMissionResponseFiles	 		: (params) => requests.getFile('/ReOrgAppealMission/DownloadAllReOrgAppealMissionResponseFiles', params), // Скачивание архива со всеми файлами ответа поручения
}
